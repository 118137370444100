import React, { useEffect, useState } from 'react'
import { Page, Layout, DetailPageSlider } from "../../components";
import { withStyles, Container, Grid, Box, Typography, Button, CircularProgress } from '@material-ui/core/';
import Skeleton from '@material-ui/lab/Skeleton';
import ProductAttributes from '../../components/ProductAttributes';
import { useAllProductsData, useCartData } from '../../helpers';
import ProductItemLoader from '../../components/ProductItemLoader';
import CategoryItem from '../../components/CategoryItem';
import { ChevronRight } from '@material-ui/icons';
import { BsArrowRight } from 'react-icons/bs';

import { Link } from "gatsby"
const image = 'https://images.unsplash.com/photo-1622497170185-5d668f816a56?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1921&q=80';
const bgImage = 'https://images.unsplash.com/photo-1511335413948-b5966e4e1d01?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1052&q=80';

const CategoryPage = (props) => {
  const [dataList, setDataList] = useState('');
  const [name, setName] = useState('');
  const [_menuId, setMenuId] = useState('');
  const [_list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [breadcrumbLink, setBreadcrumbLink] = useState('');
  let menuId = props?.location?.search.slice(6);
  const id = props.id;
  const allProducts = useAllProductsData();
  const mainSubCategoryData = allProducts?.mainSubCategoryData;
  const categoriesLoading = allProducts?.mainSubCategoryDataLoading;

  const setMenuList = async () => {
    setBreadcrumbLink('');
    const list = mainSubCategoryData?.find(l => l.MenuId === parseInt(id));
    await setDataList(list);
    setName(list?.MenuName);
    setList(list?.Category);
    setMenuId(dataList?.MenuId);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }

  const setCategroryList = async () => {
    const list = mainSubCategoryData?.find(l => l.MenuId === parseInt(menuId));
    setBreadcrumbLink({
      MenuId: list?.MenuId,
      MenuName: list?.MenuName
    });
    const categoryList = list?.Category?.find(l => l.CategoryId === parseInt(id));
    setName(categoryList?.Category);
    setList(categoryList?.SubCategory);
    setMenuId(dataList?.MenuId);
    await setDataList(categoryList);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }

  useEffect(() => {
    setLoading(true);
    if (menuId) {
      setCategroryList();
    } else {
      setMenuList();
    }
  }, [allProducts?.mainSubCategoryData, id])

  return (
    <Layout>
      <Page>
        {loading ? <Box>
          <Box paddingBottom={10}>
            <Skeleton variant="rect" width='100%' height={'20vh'} color='secondary' size={100} />
          </Box>
          <Container>
            <Grid container spacing={3}>
              {Array.apply(null, { length: 4 }).map((e, i) => (
                <Grid item md={3} lg={3} xs={12} sm={12} key={i}>
                  <ProductItemLoader />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box> : <>
          <Box className='listPageTitleContainer' style={{ backgroundImage: `url(${bgImage})` }}>
            <h2>{name}</h2>
            <em> {breadcrumbLink && <> <Link to={`/categories/${breadcrumbLink.MenuId}`} style={{ color: '#fff' }}><em>{breadcrumbLink.MenuName}</em></Link><em style={{ margin: '0 8px' }}>/</em></>} {typeof dataList?.MainCategoryDesc === 'string' ? dataList?.MainCategoryDesc : ''}</em>
          </Box>
          <Container>
            {menuId ? <>
              {_list?.length > 0 ? <Box paddingY={10}>
                <Grid container spacing={2}>
                  {_list?.map((item, i) => (<Grid key={i} item md={3} lg={3} xs={12} sm={12}>
                    {console.log("item", item)}
                    <CategoryItem
                      title={typeof item.SubCategory === 'string' ? item.SubCategory : ''}
                      slug={item.CategoryId} image={item.Images && item.Images[0]?.MediaURL}
                      loading={categoriesLoading}
                      categoryList
                      subCategoryId={item.SubCategoryId}
                      menuId={dataList?.MenuId}
                    />
                  </Grid>))}
                </Grid>
              </Box> : <Box display='flex' alignItems='center' justifyContent='center' style={{ height: '50vh', width: '100%', flexDirection: 'column' }}>
                <Typography variant='h5' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  Oops! No data found.
                </Typography>
              </Box>}
            </> : <>
              {_list?.length > 0 ? <Box paddingY={10}>
                <Grid container spacing={2}>
                  {_list?.map((item, i) => (<Grid key={i} item md={3} lg={3} xs={12} sm={12}>
                    <CategoryItem
                      title={item.Category || ''}
                      slug={item.CategoryId} image={item.Images && item.Images[0]?.MediaURL}
                      loading={categoriesLoading}
                      menuList
                      productExists={item.Productexists}
                      menuId={dataList?.MenuId}
                    />
                  </Grid>))}
                </Grid>
              </Box> : <Box display='flex' alignItems='center' justifyContent='center' style={{ height: '50vh', width: '100%', flexDirection: 'column' }}>
                <Typography variant='h5' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  Oops! No data found.
                </Typography>
              </Box>}
            </>}
          </Container>
        </>}
      </Page>
    </Layout>
  )
}

const styles = (theme) => ({

})
export default withStyles(styles, { withTheme: true })(CategoryPage)
